<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/6 w-full">
      <span>Budget Source</span>
    </div>
    <div class="vx-col sm:w-5/6 w-full">
      <ul class="centerx">
        <li v-for="(item, index) in budgetSourceList" :key="index">
          <vs-checkbox :disabled="disable" v-model="item.checked">{{item.name}}</vs-checkbox>
          <div class="vx-row mb-6 mt-2">
            <div class="vx-col sm:w-1/4">
              <vx-input-group>
                <vs-input
                  :disabled="disable"
                  v-if="item.checked"
                  type="number"
                  min="0"
                  max="100"
                  v-model="percentage[index].value"
                />
                <vs-input v-else type="number" min="0" max="100" value="0" disabled />
                <template slot="append">
                  <div class="append-text bg-primary">
                    <span>%</span>
                  </div>
                </template>
              </vx-input-group>
            </div>
            <div class="vx-col sm:w-3/4">
              <vx-input-group>
                <template slot="prepend">
                  <div class="prepend-text bg-primary">
                    <span>Rp</span>
                  </div>
                </template>
                <vs-input
                  v-if="item.checked"
                  min="0"
                  v-model="amount[index].value"
                  @keyup="amount[index].value= formatPrice(amount[index].value.toString())"
                  disabled
                />
                <vs-input v-else type="number" min="0" value="0" disabled />
              </vx-input-group>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    budgetValue: Number,
    budgetSource: Array,
    disable: Boolean,
  },
  data() {
    return {
      amount: [],
      percentage: [],
      budgetSourceList: [
        {
          value: "1",
          name: "Marketing",
          checked: false,
        },
        {
          value: "2",
          name: "Sales",
          checked: false,
        },
        {
          value: "3",
          name: "Principal",
          checked: false,
        },
      ],
    };
  },
  methods: {
    formatPrice(angka, prefix = "") {
      var number_string = angka.replace(/[^,\d]/g, "").toString();
      var split = number_string.split(",");
      var sisa = split[0].length % 3;
      var rupiah = split[0].substr(0, sisa);
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      var separator = "";
      if (ribuan) {
        if (sisa) {
          separator = ".";
        } else {
          separator = "";
        }
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
    },
    setValue() {
      var percentage = [];
      var amount = [];
      this.budgetSourceList.forEach(function (item, index) {
        percentage[index] = { value: 0 };
        amount[index] = { value: "0" };
      });
      this.percentage = percentage;
      this.amount = amount;
    },
    setPercentage() {
      var checked = 0;
      var percentage = this.percentage;
      this.budgetSourceList.forEach(function (item) {
        if (item.checked) {
          checked++;
        }
      });

      var prorate = 100 / checked;
      var percentageAfter = 0;
      this.budgetSourceList.forEach(function (item, index) {
        if (item.checked) {
          if (percentage[index].value == 0) {
            if (index == percentage.length - 1) {
              percentage[index].value = 100 - percentageAfter;
            } else {
              percentage[index].value = prorate;
              percentageAfter += prorate;
            }
          } else {
            percentageAfter += percentage[index].value;
          }
        } else {
          percentage[index].value = 0;
        }
      });
      this.percentage = percentage;
    },
    setAmountByBudgetValue() {
      var amount = this.amount;
      var percentage = this.percentage;
      var budgetValue = this.budgetValue;
      this.budgetSourceList.forEach(function (item, index) {
        if (item.checked) {
          amount[index].value = (percentage[index].value / 100) * budgetValue;
        }
      });
    },
    setAmountByPercentage() {
      var amount = this.amount;
      var percentage = this.percentage;
      var budgetValue = this.budgetValue;
      var formatPrice = this.formatPrice;
      this.budgetSourceList.forEach(function (item, index) {
        if (item.checked) {
          var value = (percentage[index].value / 100) * budgetValue;
          amount[index].value = formatPrice(value.toString());
        }
      });
    },
    emitData() {
      var data = [];
      var percentage = this.percentage;
      var amount = this.amount;
      this.budgetSourceList.forEach(function (item, index) {
        if (item.checked) {
          data.push({
            source: item.value,
            percentage: parseInt(percentage[index].value),
            amount: parseInt(amount[index].value.toString().replace(/\./g, "")),
          });
        }
      });

      this.$emit("dataBudgetSource", { val: data, obj: "budgetSource" });
    },
    emitBudgetValue() {
      var data = 0;
      var amount = this.amount;
      this.budgetSourceList.forEach(function (item, index) {
        if (item.checked) {
          var val = amount[index].value.toString().replace(/\./g, "");
          if (val != "") {
            data += parseInt(val);
          }
        }
      });
      this.$emit("dataBudgetValue", { val: data, obj: "budgetValue" });
    },
  },
  computed: {},
  watch: {
    budgetSourceList: {
      handler: function (newValue, oldValue) {
        this.emitData();
      },
      deep: true,
    },
    amount: {
      deep: true,
      handler: function (newValue, oldValue) {
        this.emitData();
      },
    },
    percentage: {
      deep: true,
      handler: function (newValue, oldValue) {
        this.setAmountByPercentage();
      },
    },
    budgetValue(val) {
      this.setAmountByBudgetValue();
    },
    budgetSource: {
      deep: true,
      handler: function (val) {
        var formatPrice = this.formatPrice;
        var amount = this.amount;
        var percentage = this.percentage;
        this.budgetSourceList.forEach(function (item, index) {
          val.forEach(function (i) {
            if (i.source == item.value) {
              item.checked = true;
              amount[index].value = formatPrice(i.amount.toString());
              percentage[index].value = i.percentage;
            }
          });
        });
      },
    },
    disable(val) {
      console.log(val);
    },
  },
  mounted() {
    // console.log(this.disabled);
    this.setValue();
  },
};
</script>