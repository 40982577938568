<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/6 w-full">
      <span>{{label}}</span>
    </div>
    <div class="vx-col sm:w-5/6 w-full">
      <vs-input
        :disabled="disabled"
        class="w-full"
        v-model="mutableValue"
        :placeholder="placeholder"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    obj: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: function () {
        return this.label;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      mutableValue: "",
    };
  },
  mounted() {
    this.mutableValue = this.value;
  },
  watch: {
    value(val) {
      this.mutableValue = val;
    },
    mutableValue(val) {
      var obj = this.obj;
      this.$emit("data", { val, obj });
    },
  },
};
</script>