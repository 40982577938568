<template>
  <div class="con-tab-ejemplo">
    <div v-if="Object.keys(value).length > 0" class="vx-row mb-6" style="padding:10px">
      Price Rule : {{value.code}} {{value.name}}
      <br />
      <br />
      Sales Channel : {{value.salesChannel.map(e => e.sales_channel_code+" "+e.sales_channel_name).join(",\n")}}
      <br />
      <br />
      Sales Area : {{value.salesArea.map(e => e.sales_area_code +" "+e.sales_area_name).join(",\n")}}
      <br />
      <br />
      Distribution Channel : {{value.customerCategory.map(e => e.customer_category_name).join(",\n")}}
      <br />
      <br />
      Customer Group : {{value.customerGroup.map(e => e.customer_group_name).join(",\n")}}
      <br />
      <br />
      Customer : {{ (isAllCustomer(value.customerTotal, value.customerPriceRuleTotal))?'All':value.customer.map(e => e.customer_code+" "+ e.customer_name).join(",\n")}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
  mounted() {},
  computed: {},
  methods: {
    isAllCustomer(a, b) {
      return a > 0 && b > 0 ? (a == b ? true : false) : false;
    },
  },
  watch: {},
};
</script>