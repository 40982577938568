<template>
  <div class="vx-col md:w-1/1 w-full">
    <vx-card title="Promotion">
      <vs-tabs v-model="tabIndex">
        <vs-tab @click="colorx = 'danger'" label="List" v-if="this.$route.path=='/promotion'">
          <div class="con-tab-ejemplo">
            <list-promotion></list-promotion>
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'danger'" label="Form">
          <div class="con-tab-ejemplo">
            <form-promotion></form-promotion>
          </div>
        </vs-tab>
        <!--
        <vs-tab @click="colorx = 'danger'" label="Validation" v-if="this.$route.path=='/promotion'">
          <div class="con-tab-ejemplo">
            <validation-promotion></validation-promotion>
          </div>
        </vs-tab>
        -->
        <vs-tab
          @click="colorx = 'danger'"
          label="Approval"
          v-if="this.$route.path=='/approval/promotion'"
        >
          <div class="con-tab-ejemplo">
            <approval-promotion></approval-promotion>
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'danger'" label="Release" v-if="this.$route.path=='/promotion'">
          <div class="con-tab-ejemplo">
            <release-promotion></release-promotion>
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'danger'" label="Released">
          <div class="con-tab-ejemplo">
            <complete-promotion></complete-promotion>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>
<script>
import form from "./form/form.vue";
import list from "./list/list.vue";
import validation from "./validation/validation.vue";
import approval from "./approval/approval.vue";
import release from "./release/release.vue";
import complete from "./complete/complete.vue";

export default {
  components: {
    "form-promotion": form,
    "list-promotion": list,
    "validation-promotion": validation,
    "approval-promotion": approval,
    "release-promotion": release,
    "complete-promotion": complete,
  },
  data: () => ({
    selected: {
      itemKit: {},
      isEdit: true,
    },
  }),
  watch: {
    tabIndex(val) {
      // console.log(val);
    },
  },
  methods: {},
  mounted() {},
  beforeMount() {
    if (this.$route.path == "/approval/promotion") {
      this.$store.dispatch("promotion/setTabIndex", 1);
      this.$store.dispatch("promotion/setIsEdit", false);
    }
  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.state.promotion.tabIndex;
      },
      set(data) {
        this.$store.dispatch("promotion/setTabIndex", data);
      },
    },
  },
};
</script>
<style >
.multiselect__tags {
  min-height: 32px;
  display: block;
  padding: 3px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
.multiselect__content-wrapper {
  z-index: 100;
  background: #fff;
}
</style>