<template>
  <div class="con-tab-ejemplo">
    <div class="vx-row mb-6" v-if="promotionType == 'Special Price'" style="margin:1px">
      <TablePriceItem :priceId="priceId"></TablePriceItem>
    </div>
    <div class="vx-row mb-6" v-else>
      <vs-table stripe bordered :data="items" style="width:100%">
        <template slot="thead">
          <vs-th>Code</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Price</vs-th>
          <vs-th>Action</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="index" v-for="(dt, index) in data">
            <vs-td>{{dt.sku_code}}</vs-td>
            <vs-td>{{dt.name}}</vs-td>
            <vs-td>{{dt.price}}</vs-td>
            <vs-td style="align-item-center">
              <vx-tooltip v-if="isEdit!=false" text="Delete Item">
                <feather-icon
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 text-danger stroke-current"
                  class="ml-2"
                  @click="deleteItemLine(index,dt)"
                />
              </vx-tooltip>
              <vx-tooltip v-else text="Cant Delete">
                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 stroke-current" class="ml-2" />
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import TablePriceItem from "./TablePriceItem";
export default {
  components: {
    TablePriceItem,
  },
  props: {
    promotionType: {
      type: String,
    },
    items: {
      type: Array,
    },
    priceRuleId: {
      type: Number,
    },
    priceId: {
      type: Number,
    },
  },
  data() {
    return {
      mutableItems: [],
    };
  },
  mounted() {},
  watch: {
    items(val) {
      // console.log(val);
    },
  },
  computed: {
    isEdit() {
      return this.$store.state.promotion.isEdit;
    },
  },
  methods: {
    deleteItemLine(index, dt) {
      this.indexDelete = index;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure remove " + dt.name + " from lines?",
        accept: this.confirmDelete,
      });
    },
    confirmDelete() {
      this.$vs.loading();
      var items = this.items;
      setTimeout(() => {
        this.$vs.loading.close();
        if (items[this.indexDelete].ID) {
          this.data.price = 0;
        }
        items.splice(this.indexDelete, 1);
        const obj = "items";
        var val = items;
        this.$emit("items", { val, obj });
      }, 1000);
    },
  },
};
</script>