<template>
  <div>
    <div class="vx-row mb-6">
      <h3>Price Rule</h3>
    </div>
    <div class="vx-row mb-6">
      <data-table
        :responseData="tableData"
        :propsParams="params"
        :header="header"
        @reloadDataFromChild="reloadData"
        style="padding-left:10px;width:100%"
      >
        <template slot="tbody">
          <vs-tr :key="indextr" v-for="(tr, indextr) in tableData.data">
            <vs-td>{{tr.code}}</vs-td>
            <vs-td>{{tr.name}}</vs-td>
            <vs-td>{{tr.price_code}}</vs-td>
            <vs-td>
              <vx-tooltip v-if="isEdit!=false" text="Add items">
                <feather-icon
                  icon="PlusIcon"
                  svgClasses="w-5 h-5 text-success stroke-current"
                  class="ml-2"
                  @click="addPriceRuleToLines(tr)"
                />
              </vx-tooltip>
              <vx-tooltip v-else text="Cant Add Item">
                <feather-icon icon="PlusIcon" svgClasses="w-5 h-5  stroke-current" class="ml-2" />
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </data-table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabIndex: 0,
      priceRule: {
        id: null,
        name: "",
        code: "",
        customer: [],
        customerCategory: [],
        customerGroup: [],
        salesArea: [],
        salesChannel: [],
        territory: [],
      },
      tableData: {
        data: [],
        recordsTotal: 0,
        length: 0,
      },
      header: [
        {
          text: "Code",
          // value: "sku_code"
          sortable: false,
        },
        {
          text: "Name",
          // value: "item_name"
          sortable: false,
        },
        {
          text: "Unit",
          // value: "name"
          sortable: false,
        },
        {
          text: "Action",
          sortable: false,
        },
      ],
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "",
        sort: "",
      },
    };
  },
  methods: {
    addPriceRuleToLines(item) {
      var territory = [];
      for (var t in item.territory) {
        territory.push(item.territory[t]);
        territory[t].quota = 999999999;
      }
      console.log(item)
      this.priceRule = {
        id: item.id,
        priceId: item.price_id,
        name: item.name,
        code: item.code,
        customer: item.customer,
        customerTotal: item.customer_total,
        customerPriceRuleTotal: item.customer_price_rule_total,
        customerCategory: item.customer_category,
        customerGroup: item.customer_group,
        salesArea: item.sales_area,
        salesChannel: item.sales_channel,
        territory: territory,
      };

      this.$store.dispatch("promotion/setFormObj", {
        name: "tabIndex",
        data: 1,
      });
    },
    reloadData(params) {
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/price-rule", {
          params: {
            search: params.search,
            limit: params.length,
            offset: params.length * (params.page - 1),
            order: params.sort,
            sort: params.order,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.tableData = {
              data: resp.data.records,
              recordsTotal: resp.data.total_record,
              length: resp.data.total_record_per_page,
            };
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
  watch: {
    priceRule: function (val) {
      const obj = "priceRule";
      this.$emit("data", { val, obj });
    },
  },
  computed: {},
};
</script>