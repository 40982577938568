<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/6 w-full">
      <span>Require Kitting</span>
    </div>
    <div class="vx-col sm:w-5/6 w-full">
      <ul class="centerx">
        <li>
          <vs-checkbox v-model="mutableValue" :disabled="disabled"></vs-checkbox>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
    },
    obj: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mutableValue: "",
    };
  },
  mounted() {
    this.mutableValue = this.value;
  },
  watch: {
    value(val) {
      this.mutableValue = val;
    },
    mutableValue(val) {
      var obj = this.obj;
      this.$emit("data", { val, obj });
    },
  },
};
</script>