<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/6 w-full">
      <span>Promotion Type</span>
    </div>
    <div class="vx-col sm:w-5/6 w-full">
      <multiselect
        v-model="mutableValue"
        :options="options"
        :searchable="false"
        :showNoOptions="false"
        :max-height="125"
        :allow-empty="false"
        :disabled="disabled"
      ></multiselect>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    obj: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      options: ["Special Price", "Free Item", "Bundling"],
      mutableValue: "",
    };
  },
  mounted() {
    this.mutableValue = this.value;
  },
  watch: {
    value(val) {
      this.mutableValue = val;
    },
    mutableValue(val) {
      var obj = this.obj;
      this.$emit("data", { val, obj });
    },
  },
};
</script>