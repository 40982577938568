<template>
  <div>
    <div class="vx-row mb-6">
      <h3>Item</h3>
    </div>
    <div class="vx-row mb-6">
      <!-- <data-table
        :responseData="table.data"
        :propsParams="table.params"
        :header="table.header"
        @reloadDataFromChild="getData"
        style="padding-left:10px;width:100%"
      >
        <template slot="tbody">
          <vs-tr :key="indextr" v-for="(tr, indextr) in table.data.data">
            <vs-td>{{tr.code}}</vs-td>
            <vs-td>{{tr.name}}</vs-td>
            <vs-td>{{tr.uom}}</vs-td>
            <vs-td>
              <vx-tooltip v-if="isEdit!=false" text="Add items">
                <feather-icon
                  icon="PlusIcon"
                  svgClasses="w-5 h-5 text-success stroke-current"
                  class="ml-2"
                  @click="addItemsToLines(tr)"
                />
              </vx-tooltip>
              <vx-tooltip v-else text="Cant Add Item">
                <feather-icon icon="PlusIcon" svgClasses="w-5 h-5  stroke-current" class="ml-2" />
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </data-table>-->

      <div style="width:100%">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">{{this.table.start}} - {{this.table.end}} of {{this.table.total}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{item}}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <!-- <vs-th sort-key width="5%">No</vs-th> -->
            <vs-th sort-key="sku_code">Sku Code</vs-th>
            <vs-th sort-key="item_name">Item Name</vs-th>
            <vs-th sort-key="item_unit">UOM</vs-th>
            <vs-th sort-key="price">Price</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ tr.sku_code }}</vs-td>
              <vs-td>{{ tr.item_name }}</vs-td>
              <vs-td>{{ tr.item_unit }}</vs-td>
              <vs-td>{{ tr.price }}</vs-td>
              <vs-td>
                <vx-tooltip v-if="isEdit!=false" text="Add items">
                  <feather-icon
                    icon="PlusIcon"
                    svgClasses="w-5 h-5 text-success stroke-current"
                    class="ml-2"
                    @click="addItemsToLines(tr)"
                  />
                </vx-tooltip>
                <vx-tooltip v-else text="Cant Add Item">
                  <feather-icon icon="PlusIcon" svgClasses="w-5 h-5  stroke-current" class="ml-2" />
                </vx-tooltip>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination style="padding-top:5px" :total="table.totalPage" v-model="setPage" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    requireKitting: {
      type: Boolean,
      default: false,
    },
    priceId: {
      type: Number,
    },
  },
  data() {
    return {
      table: this.tableDefaultState(),
    };
  },
  mounted() {
    this.getData(this.table.params);
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    getData(params) {
      if (this.priceId) {
        this.params = params;
        this.$vs.loading();
        // var url = "";
        // if (this.requireKitting) {
        //   url = "/api/v1/kitting-management/data-table";
        // } else {
        //   url = "/api/v1/item-available";
        // }
        this.$http
          .get("/api/v1/master/price-item/" + this.priceId, {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort,
              isKitting: true,
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;
              this.setStartEnd();
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    addItemsToLines(data) {
      if (this.priceId < 1) {
        this.$vs.notify({
          title: "Error",
          text: "Please select price rule first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return true;
      }
      this.$emit("data", data);
    },
  },
  watch: {
    requireKitting(val) {
      this.getData();
    },
    priceId(val) {
      console.log(val);
      this.getData();
    },
    priceRuleTerritory(val) {
      console.log(val);
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>