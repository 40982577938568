<template>
  <div>
    <div class="vx-row mb-12">
      <vs-button v-on:click="submitValidation()" icon="done" color="primary">Submit</vs-button>
    </div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <th width="15%">
              <vs-checkbox
                color="success"
                v-on:click="addAllValidate()"
                v-model="validateAll"
              >Release All</vs-checkbox>
            </th>
          </template>
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.promotions">
              <vs-td>
                <vs-checkbox
                  color="success"
                  :checked="validate.includes(tr.promotion.ID)"
                  v-on:click="addValidate(tr.promotion.ID)"
                >Release</vs-checkbox>
              </vs-td>
              <vs-td>
                <vs-button
                  v-on:click="clickShow(tr)"
                  color="primary"
                  type="gradient"
                  icon-pack="feather"
                  icon="icon-search"
                >{{tr.promotion.Code}}</vs-button>
                Code : {{tr.promotion.Code}}
                <br />
                Name : {{tr.promotion.Name}}
                <br />
                Desc : {{tr.promotion.Description}}
                <br />
              </vs-td>
              <vs-td>
                Code : {{tr.price_rule.Code}}
                <br />
                Name : {{tr.price_rule.Name}}
                <br />
              </vs-td>
              <!-- <vs-td>{{data[indextr].Code}}</vs-td> -->
              <vs-td>{{tr.territory.map(e => e.territory_name).join("\n")}}</vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "code",
      },
      header: [
        {
          text: "Promotion",
          value: "code",
          // width: '5%'
        },
        {
          text: "Price Rule",
          sortable: false,
        },
        {
          text: "Territory",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      selectedData: {},
      validateAll: false,
      validate: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.reloadData(this.params);
  },
  methods: {
    clickShow(tr) {
      this.$store.dispatch("promotion/setPromotionId", tr.promotion.ID);
      this.$store.dispatch("promotion/setTabIndex", 1);
      this.$store.dispatch("promotion/setIsEdit", false);
    },
    addValidate(ID) {
      if (this.validate.includes(ID)) {
        this.validate.splice(this.validate.indexOf(ID), 1);
      } else {
        this.validate.push(ID);
      }
    },
    addAllValidate() {
      if (this.validateAll == false) {
        for (var i in this.responseData.promotions) {
          if (
            !this.validate.includes(
              this.responseData.promotions[i].promotion.ID
            )
          ) {
            this.validate.push(this.responseData.promotions[i].promotion.ID);
          }
        }
      } else {
        this.validate = [];
      }
    },
    submitValidation() {
      this.$http
        .put("api/v1/promotion/status/update", {
          promotion_ids: this.validate,
          Status: parseInt(3),
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        });
    },
    reloadData(params) {
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/promotion/data-table", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            status: 2,
          },
        })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.promotions;
            this.responseData = resp.data;
            this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
};
</script>
 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.colored {
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>