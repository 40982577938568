<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/6 w-full">
      <span>{{label}}</span>
    </div>
    <div class="vx-col sm:w-5/6 w-full">
      <flat-pickr
        :disabled="disabled"
        :config="defaultConfig"
        v-model="mutableValue"
        :placeholder="placeholder"
        @on-change="onChange"
      />
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: function () {
        return this.label;
      },
    },
    obj: {
      type: String,
      required: true,
    },
    minDate: {
      default: null,
    },
    maxDate: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },
  components: {
    flatPickr,
  },
  data() {
    return {
      mutableValue: "",
      defaultConfig: {
        altFormat: "F j, Y",
        dateFormat: "j F Y",
        minDate: null,
        maxDate: null,
      },
    };
  },
  methods: {
    onChange(selectedDates, dateStr, instance) {
      this.$emit("on-change", dateStr);
    },
  },
  mounted() {
    this.mutableValue = this.value;
  },
  watch: {
    minDate(val) {
      this.$set(this.defaultConfig, "minDate", val);
    },
    maxDate(val) {
      this.$set(this.defaultConfig, "maxDate", val);
    },
    value(val) {
      this.mutableValue = val;
    },
    mutableValue(val) {
      var obj = this.obj;
      this.$emit("data", { val, obj });
    },
  },
};
</script>