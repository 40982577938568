<template>
  <div class="vx-row">
    <div class="vx-col sm:w-1/2 w-full mb-base">
      <TextField
        label="Code"
        obj="code"
        :disabled="!isEdit"
        :value="promotion.code"
        @data="setPromotionObj"
      ></TextField>
      <TextField
        label="Name"
        obj="name"
        :disabled="!isEdit"
        :value="promotion.name"
        @data="setPromotionObj"
      ></TextField>
      <PromotionType
        obj="promotionType"
        :disabled="!isEdit"
        :value="promotion.promotionType"
        @data="setPromotionObj"
      ></PromotionType>
      <!--
      <RequireKitting
        obj="requireKitting"
        :disabled="!isEdit"
        :value="promotion.requireKitting"
        @data="setPromotionObj"
        v-if="promotion.promotionType!='Special Price'"
      ></RequireKitting>
      -->
      <Description
        obj="description"
        :disabled="!isEdit"
        :value="promotion.description"
        @data="setPromotionObj"
      ></Description>
      <DateField
        label="Valid From"
        obj="validFrom"
        :disabled="!isEdit"
        :maxDate="data.toDate"
        :value="promotion.validFrom"
        @on-change="onFromChange"
        @data="setPromotionObj"
      ></DateField>
      <DateField
        label="Valid To"
        obj="validTo"
        :disabled="!isEdit"
        :minDate="data.fromDate"
        :value="promotion.validTo"
        @on-change="onToChange"
        @data="setPromotionObj"
      ></DateField>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Budget Value</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vx-input-group>
            <template slot="prepend">
              <div class="prepend-text bg-primary">
                <span>Rp</span>
              </div>
            </template>
            <vs-input
              :disabled="!isEdit"
              type="text"
              v-model="promotion.budgetValue"
              @keyup="promotion.budgetValue = formatPrice(promotion.budgetValue.toString())"
            />
          </vx-input-group>
        </div>
      </div>
      <budget-source
        :disable="!isEdit"
        :budgetValue="budgetValue"
        :budgetSource="promotion.budgetSource"
        @dataBudgetSource="setPromotionObj"
        @dataBudgetValue="setPromotionObj"
      ></budget-source>
      <budget-quota
        v-if="promotion.promotionType!='Special Price'"
        :budgetQuota="promotion.budgetQuota"
        :budgetValue="budgetValue"
        :price="data.price"
        @data="setPromotionObj"
      ></budget-quota>
      <div class="vx-row mb-6" v-if="isEdit">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Price Rule</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-button size="small" class="mr-3 mb-2" @click="browsePriceRule()">Browse</vs-button>
        </div>
      </div>
      <div class="vx-row mb-6" v-if="promotion.promotionType!='Special Price' && isEdit">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Items</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-button size="small" class="mr-3 mb-2" @click="browseItem()">Browse</vs-button>
        </div>
      </div>
      <vs-tabs v-model="tabIndex">
        <vs-tab @click="colorx = 'danger'" label="Item / Kitting">
          <tab-item
            :promotionType="promotion.promotionType"
            :items="promotion.items"
            :priceRuleId="promotion.priceRule.id"
            :priceId="promotion.priceRule.priceId"
          ></tab-item>
        </vs-tab>
        <vs-tab @click="colorx = 'danger'" label="Price Rule">
          <tab-price-rule :value="promotion.priceRule"></tab-price-rule>
        </vs-tab>
        <vs-tab @click="colorx = 'danger'" label="Territory">
          <tab-territory :value="promotion.priceRule"></tab-territory>
        </vs-tab>
      </vs-tabs>
      <div class="vx-row mb-6" v-if="isEdit">
        <div class="vx-col sm:w-6/6 w-full">
          <vs-button class="mr-3 mb-2" @click="handleSubmit()">Submit</vs-button>
        </div>
      </div>
    </div>
    <div class="vx-col sm:w-1/2 w-full mb-base" v-if="isEdit">
      <PriceRuleTable v-if="priceRuleTable" :isEdit="isEdit" @data="setPromotionObj"></PriceRuleTable>
      <ItemTable
        v-else
        :isEdit="isEdit"
        :requireKitting="promotion.requireKitting"
        :priceId="promotion.priceRule.priceId"
        @data="addItems"
        @items="setPromotionObj"
      ></ItemTable>
    </div>
  </div>
</template>
<script>
import TextField from "./component/TextField";
import DateField from "./component/DateField";
import PromotionType from "./component/PromotionType";
import RequireKitting from "./component/RequireKitting";
import Description from "./component/Description";
import BudgetSource from "./component/BudgetSource";
import BudgetQuota from "./component/BudgetQuota";
import ItemTable from "./component/ItemTable";
import PriceRuleTable from "./component/PriceRuleTable";
import TabItem from "./component/TabItem";
import TabPriceRule from "./component/TabPriceRule";
import TabTerritory from "./component/TabTerritory";

export default {
  components: {
    TextField,
    DateField,
    PromotionType,
    RequireKitting,
    Description,
    BudgetSource,
    BudgetQuota,

    ItemTable,
    PriceRuleTable,
    TabItem,
    TabPriceRule,
    TabTerritory,
  },
  data: () => ({
    baseUrl: "/api/v1/promotion",
    data: {
      fromDate: null,
      toDate: null,
      price: 0,
    },
    promotion: {
      id: 0,
      code: "",
      name: "",
      promotionType: "Special Price",
      requireKitting: false,
      description: "",
      validFrom: "",
      validTo: "",
      budgetValue: 0,
      budgetSource: [],
      budgetQuota: 0,
      priceRule: {
        id: null,
        priceId: null,
        name: "",
        code: "",
        customer: [],
        customerCategory: [],
        customerGroup: [],
        customerTotal: 0,
        customerPriceRuleTotal: 0,
        salesArea: [],
        salesChannel: [],
        territory: [],
      },
      items: [],
    },
    priceRuleTable: true,
  }),
  computed: {
    tabIndex: {
      get() {
        return this.$store.state.promotion.form.tabIndex;
      },
      set(val) {
        this.$store.dispatch("promotion/setFormObj", {
          name: "tabIndex",
          data: val,
        });
      },
    },
    isEdit() {
      return this.$store.state.promotion.isEdit;
    },
    budgetValue() {
      return parseInt(this.promotion.budgetValue.toString().replace(/\./g, ""));
    },
  },
  mounted() {
    if (this.$store.state.promotion.promotionId) {
      this.getData();
    }
  },
  watch: {
    "promotion.promotionType": function (val) {
      if (val == "Special Price") {
        this.priceRuleTable = true;
        this.promotion.items = [];
      }
    },
  },
  methods: {
    onFromChange(dateStr) {
      this.data.fromDate = dateStr;
    },
    onToChange(dateStr) {
      this.data.toDate = dateStr;
    },
    browsePriceRule() {
      this.priceRuleTable = true;
    },
    browseItem() {
      if (this.promotion.priceRule.id < 1) {
        this.$vs.notify({
          title: "Error",
          text: "Please choose price rule",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return true;
      } else {
        this.priceRuleTable = false;
      }
    },
    formatPrice(angka, prefix = "") {
      var number_string = angka.replace(/[^,\d]/g, "").toString();
      var split = number_string.split(",");
      var sisa = split[0].length % 3;
      var rupiah = split[0].substr(0, sisa);
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      var separator = "";
      if (ribuan) {
        if (sisa) {
          separator = ".";
        } else {
          separator = "";
        }
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
    },
    setPromotionObj({ val, obj }) {
      this.promotion[obj] = val;
    },
    paramData() {
      return {
        id: this.promotion.ID,
        code: this.promotion.code,
        name: this.promotion.name,
        require_kitting: this.promotion.requireKitting,
        description: this.promotion.description,
        promotion_type: this.promotion.promotionType,
        valid_from: this.promotion.validFrom,
        valid_to: this.promotion.validTo,
        budget_value: parseFloat(
          this.promotion.budgetValue.toString().replace(/\./g, "")
        ),
        budget_quota: this.promotion.budgetQuota,
        budget_source: this.promotion.budgetSource,
        territory: this.promotion.priceRule.territory,
        items: this.promotion.items,
        price_rule: {
          id: this.promotion.priceRule.id,
          code: "",
          name: "",
        },
      };
    },
    handleSubmit() {
      let text = "";
      let budgetValue = this.promotion.budgetValue
        .toString()
        .replace(/\./g, "");

      if (
        this.promotion.promotionType != "Special Price" &&
        this.promotion.items.length == 0
      ) {
        text = "Please choose item";
      }
      console.log(this.promotion.priceRule)
      if (this.promotion.priceRule.id == null) {
        text = "Please choose price rule";
      }
      if (this.promotion.budgetSource.length == 0) {
        text = "Please choose budget source one or more";
      } else {
        let total = 0;
        this.promotion.budgetSource.forEach(function (data) {
          total += data.percentage;
        });
        if (total != 100) {
          text = "Budget Source percentage total must equal 100%";
        }
      }
      if (this.promotion.validFrom == "" || this.promotion.validTo == "") {
        text = "Valid date is required";
      }

      if (budgetValue < 1) {
        text = "Budget Value is required";
      }
      if (this.promotion.name == "") {
        text = "Name is required";
      }
      if (this.promotion.code == "") {
        text = "Code is required";
      }

      if (text != "") {
        this.$vs.notify({
          title: "Error",
          text: text,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return true;
      }
      if (this.$store.state.promotion.promotionId) {
        this.putData();
      } else {
        this.postData();
      }
    },
    putData() {
      this.$vs.loading();
      this.$http
        .put(this.baseUrl + "/store", this.paramData())
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
            this.$store.dispatch("promotion/setTabIndex", 0);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });

      this.$vs.loading.close();
    },
    postData() {
      this.$vs.loading();
      this.$http
        .post(this.baseUrl + "/store", this.paramData())
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
            this.$store.dispatch("promotion/setTabIndex", 0);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });

      this.$vs.loading.close();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("api/v1/promotion/read", {
          params: {
            promotion_id: this.$store.state.promotion.promotionId,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            var budgetSource = [];
            resp.data.promotion_budget.forEach(function (item) {
              budgetSource.push({
                source: item.BudgetDepartment,
                amount: item.Amount,
                percentage: item.Percentage,
              });
            });

            this.promotion.ID = resp.data.promotion.ID;
            this.promotion.code = resp.data.promotion.Code;
            this.promotion.name = resp.data.promotion.Name;
            this.promotion.promotionType = resp.data.promotion.PromotionType;
            this.promotion.requireKitting =
              resp.data.promotion.RequireKitting == "1" ? true : false;
            this.promotion.description = resp.data.promotion.Description;
            this.promotion.validFrom = resp.data.promotion.ValidFrom;
            this.promotion.validTo = resp.data.promotion.ValidTo;
            this.promotion.budgetValue = resp.data.promotion.BudgetValue;
            this.promotion.budgetSource = budgetSource;
            this.promotion.budgetQuota = resp.data.promotion.BudgetQuota;
            this.promotion.priceRule = {
              id: resp.data.price_rule.ID,
              priceId: resp.data.price_rule.PriceID,
              name: resp.data.price_rule.Name,
              code: resp.data.price_rule.Code,
              customer: resp.data.customer,
              customerTotal: resp.data.customer_total,
              customerPriceRuleTotal: resp.data.customer_price_rule_total,
              customerCategory: resp.data.customer_category,
              customerGroup: resp.data.customer_group,
              salesArea: resp.data.sales_area,
              salesChannel: resp.data.sales_channel,
              territory: resp.data.territory,
            };
            this.promotion.items = [];
            for (var u in resp.data.promotion_item_kitting) {
              this.promotion.items.push({
                sku_code: resp.data.promotion_item_kitting[u].SkuCode,
                name: resp.data.item_kitting.filter(function (item) {
                  return (
                    item.SkuCode == resp.data.promotion_item_kitting[u].SkuCode
                  );
                })[0].Name,
                price: resp.data.promotion_item.filter(function (item) {
                  return item.SkuCode == resp.data.promotion_item[u].SkuCode;
                })[0].Price,
              });
            }
          } else {
            console.log(resp);
            this.$vs.dialog({
              type: "alert",
              color: "danger",
              title: `Alert`,
              text: "Whoops",
            });
          }
          this.$vs.loading.close();
        });
    },
    addItems(data) {
      var itemTerritory = data.territory
        .sort()
        .map((e) => e.id)
        .join(",");
      var promoTerritory = this.promotion.priceRule.territory
        .map((e) => e.territory_id)
        .sort()
        .join(",");
      if (itemTerritory != promoTerritory) {
        this.$vs.notify({
          title: "Error",
          text: "Please check rules of promotion",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return true;
      }

      var added = this.promotion.items.filter(function (val) {
        return val.sku_code == data.sku_code;
      });
      if (added.length == 0) {
        if (this.promotion.items.length > 0) {
          this.promotion.items = [];
        }
        this.data.price = data.price;
        this.promotion.items.push({
          sku_code: data.sku_code,
          price: data.price,
          name: data.item_name,
        });
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Item added",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
      this.$store.dispatch("promotion/setFormObj", {
        name: "tabIndex",
        data: 0,
      });
    },
  },
  destroyed() {
    this.$store.dispatch("promotion/setPromotionId", null);
    this.$store.dispatch("promotion/setIsEdit", true);
  },
};
</script>;
