<template>
  <div>
    <div class="vx-row mb-12">
      <vs-button v-on:click="submitValidation()" icon="done" color="primary">Submit</vs-button>
    </div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <th width="15%">
              <div class="vx-row mb-4">
                <div class="vx-col">
                  <vs-checkbox
                    color="success"
                    v-on:click="approveCheckAll()"
                    v-model="approveAll"
                  >Approve</vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox
                    color="danger"
                    v-on:click="rejectCheckAll()"
                    v-model="rejectAll"
                  >Reject</vs-checkbox>
                </div>
              </div>
            </th>
          </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.promotions"
              v-bind:class="[selectedData.ID==tr.ID ? 'colored' : '']"
            >
              <vs-td>
                <div class="vx-row mb-4">
                  <div class="vx-col">
                    <vs-checkbox
                      color="success"
                      :checked="approve.includes(tr.promotion.ID)"
                      v-on:click="addApprove(tr.promotion.ID)"
                    >Approve</vs-checkbox>
                  </div>
                  <div class="vx-col">
                    <vs-checkbox
                      color="danger"
                      :checked="reject.includes(tr.promotion.ID)"
                      v-on:click="addReject(tr.promotion.ID)"
                    >Reject</vs-checkbox>
                  </div>
                </div>
              </vs-td>
              <vs-td>
                <vs-button
                  v-on:click="clickShow(tr)"
                  color="primary"
                  type="gradient"
                  icon-pack="feather"
                  icon="icon-search"
                >{{tr.promotion.Code}}</vs-button>
                Code : {{tr.promotion.Code}}
                <br />
                Name : {{tr.promotion.Name}}
                <br />
                Desc : {{tr.promotion.Description}}
                <br />
              </vs-td>
              <vs-td>
                Code : {{tr.price_rule.Code}}
                <br />
                Name : {{tr.price_rule.Name}}
                <br />
              </vs-td>
              <vs-td>{{tr.territory.map(e =>e.territory_code+" "+ e.territory_name).join(", ")}}</vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "code",
      },
      header: [
        {
          text: "Promotion",
          value: "code",
          // width: '5%'
        },
        {
          text: "Price Rule",
          sortable: false,
        },
        {
          text: "Territory",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      selectedData: {},
      approveAll: false,
      rejectAll: false,
      approve: [],
      reject: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.reloadData(this.params);
  },
  methods: {
    addApprove(ID) {
      if (this.approve.includes(ID)) {
        this.approve.splice(this.approve.indexOf(ID), 1); // unchecked approve
      } else {
        this.approve.push(ID); // checked approve
      }
      this.reject.splice(this.reject.indexOf(ID), 1); // unchecked reject
    },
    addReject(ID) {
      if (this.reject.includes(ID)) {
        this.reject.splice(this.approve.indexOf(ID), 1); // unchecked reject
      } else {
        this.reject.push(ID); // checked reject
      }
      this.approve.splice(this.approve.indexOf(ID), 1); // unchecked approve
    },
    rejectCheckAll() {
      if (this.rejectAll) {
        this.reject = [];
        return true;
      }
      this.approveAll = false;
      this.approve = [];
      for (var i in this.responseData.promotions) {
        if (
          !this.reject.includes(this.responseData.promotions[i].promotion.ID)
        ) {
          this.reject.push(this.responseData.promotions[i].promotion.ID);
        }
      }
    },
    approveCheckAll() {
      if (this.approveAll) {
        this.approve = [];
        return true;
      } else {
        this.reject = [];
        this.rejectAll = false;
        for (var i in this.responseData.promotions) {
          if (
            !this.approve.includes(this.responseData.promotions[i].promotion.ID)
          ) {
            this.approve.push(this.responseData.promotions[i].promotion.ID);
          }
        }
      }
    },
    clickShow(tr) {
      this.$store.dispatch("promotion/setPromotionId", tr.promotion.ID);
      if (this.$route.path == "/approval/promotion") {
        this.$store.dispatch("promotion/setTabIndex", 0);
      } else {
        this.$store.dispatch("promotion/setTabIndex", 1);
      }
      this.$store.dispatch("promotion/setIsEdit", false);
    },
    submitValidation() {
      this.$http
        .put("api/v1/promotion/approval", {
          approve: this.approve,
          reject: this.reject,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        });
    },
    reloadData(params) {
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/promotion/data-table", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            status: 1,
          },
        })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.promotions;
            this.responseData = resp.data;
            this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
};
</script>
 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.colored {
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>