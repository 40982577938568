<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/6 w-full">
      <span>Budget Quota</span>
    </div>
    <div class="vx-col sm:w-5/6 w-full">
      <vs-input type="text" :value="mutableBudgetQuota" disabled />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    budgetValue: Number,
    price: Number,
    budgetQuota: Number,
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        mutableBudgetQuota: 0,
      };
    },
    countQuota() {
      if (this.budgetValue > 0 && this.price > 0) {
        this.mutableBudgetQuota = Math.floor(this.budgetValue / this.price);
      }
    },
  },
  mounted() {
    this.mutableBudgetQuota = this.budgetQuota;
  },
  watch: {
    budgetValue() {
      this.countQuota();
    },
    budgetQuota(val) {
      this.mutableBudgetQuota = val;
    },
    price() {
      this.countQuota();
    },
    mutableBudgetQuota(val) {
      const obj = "budgetQuota";
      this.$emit("data", { val, obj });
    },
  },
};
</script>