<template>
  <div class="con-tab-ejemplo">
    <div class="vx-row mb-6">
      <vs-table stripe bordered :data="value.territory" style="width:100%">
        <template slot="thead">
          <vs-th>Code</vs-th>
          <vs-th>Name</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="index" v-for="(dt, index) in data">
            <vs-td>{{dt.territory_code}}</vs-td>
            <vs-td>{{dt.territory_name}}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
  mounted() {},
};
</script>